import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from 'firebase/compat/app'
import VueMeta from "vue-meta";

Vue.use(VueMeta);
Vue.config.productionTip = false
// import VueCookies from 'vue-cookies';
// Vue.use(VueCookies);
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
  apiKey: "AIzaSyAcbys4FVBJ0bOeINxupF9606yRiddBgFA",
  authDomain: "multipleecsearch.firebaseapp.com",
  projectId: "multipleecsearch",
  storageBucket: "multipleecsearch.appspot.com",
  messagingSenderId: "278637775666",
  appId: "1:278637775666:web:53f2d955a9269bc5c3252b",
  measurementId: "G-GJ3J814H6G"
};

// Initialize Firebase
firebase.initializeApp(config);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

