<template>
  <v-container text-center justify-center>
    <v-row justify="center">
      <h1 class="text-h2 pt-10 mt-5 font-weight-bold black--text">EC Digger</h1>
    </v-row>
    <v-row justify="center">
      <h1 class="text-h5 mt-3 font-weight-bold black--text">United States</h1>
    </v-row>
    <v-row justify="center">
      <p class="text-h5 mb-n5 font-weight-bold black--text">for quick shopping with the best choice</p>
    </v-row>


    <v-row justify="center" class="mt-15 pt-5">
      <p class="black--text font-weight-black">You can search multiple shopping sites such as Amazon, eBay, and Walmart, etc. all at once.</p>
    </v-row>
    <v-row justify="center">
      <p class="black--text font-weight-black mt-n3">This website is only compatible with PC, and the JP version is also available.</p>
    </v-row>
    <v-row justify="center">
      <p class="red--text font-weight-black mt-n3">Please disable your browser's pop-up blocker before using it for the first time.</p>
    </v-row>

  <v-form class="mt-10 mb-n10" @submit.prevent>
    <v-container>
      <v-row>
        <v-col cols="2">
        </v-col>
        <v-col cols="8">
        <!-- <v-col cols="9"> -->
          <v-text-field
            v-model="keyword"
            background-color="white"
            outlined
            clearable
            label="search word"
            type="text"
            @keypress.prevent.enter.exact="enable_submit"
            @keyup.prevent.enter.exact="submitEnter"
          >
            <template v-slot:prepend>
              <v-tooltip
                bottom
              >
                I'm a tooltip
              </v-tooltip>
            </template>
            <template v-slot:append>
              <v-fade-transition leave-absolute>
                <v-progress-circular
                  v-if="loading"
                  size="24"
                  color="info"
                  indeterminate
                ></v-progress-circular>
              </v-fade-transition>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="1">
          <v-btn x-large class="ml-n4 red darken-3" @click="submitBtn">
            <v-icon class="white--text">mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>



  <template>
  <v-row justify="center" no-gutters class="mt-10 mb-n1  text-h5">
    <p class="text-justify text-h4 black--text">
    Search Sites
    </p>
  </v-row>
  </template>

  <v-row justify="center" no-gutters>
    <v-checkbox v-model="SearchSite" value="Amazon" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold" >Amazon</div></template></v-checkbox>
    <v-checkbox v-model="SearchSite" value="Ebay" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold" >eBay</div></template></v-checkbox>
    <v-checkbox v-model="SearchSite" value="Walmart" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold" >Walmart</div></template></v-checkbox>
    <v-checkbox v-model="SearchSite" value="Etsy" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold" >Etsy</div></template></v-checkbox>
    <v-checkbox v-model="SearchSite" value="Google" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold" >Google</div></template></v-checkbox>
    <!-- <v-checkbox v-model="SearchSite" label="AmazonJP" value="AmazonJP" class="ma-2"></v-checkbox> -->
    <!-- <v-checkbox v-model="SearchSite" label="楽天市場" value="Rakuten" class="ma-2"></v-checkbox> -->
    <!-- <v-checkbox v-model="SearchSite" label="Yahoo!" value="Yahoo" class="ma-2"></v-checkbox> -->
    <!-- <v-checkbox v-model="SearchSite" label="メルカリ" value="Mercari" class="ma-2"></v-checkbox> -->
  </v-row>
  <v-row justify="center" no-gutters>
    <v-checkbox v-model="SearchSite" value="Target" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold" >Target</div></template></v-checkbox>
    <v-checkbox v-model="SearchSite" value="Bestbuy" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold" >Best Buy</div></template></v-checkbox>
    <v-checkbox v-model="SearchSite" value="Macy's" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold" >Macy's</div></template></v-checkbox>
    <v-checkbox v-model="SearchSite" value="Mercari" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold" >Mercari</div></template></v-checkbox>
 
    <!-- <v-checkbox v-model="SearchSite" label="ヤフオク" value="Yahuoku" class="ma-2" ></v-checkbox> -->
    <!-- <v-checkbox v-model="SearchSite" label="PayPay" value="Paypay" class="ma-2" ></v-checkbox> -->
    <!-- <v-checkbox v-model="SearchSite" label="ラクマ" value="Rakuma" class="ma-2" ></v-checkbox> -->
    <!-- <v-checkbox v-model="SearchSite" label="ヨドバシ" value="Yodobashi" class="ma-2"></v-checkbox> -->
  </v-row>
  <v-row justify="center" no-gutters>
  <v-checkbox v-model="SearchSite" value="Homedepot" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold">HomeDepot</div></template></v-checkbox>
    <v-checkbox v-model="SearchSite" value="Wish" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold">Wish</div></template></v-checkbox>
    <v-checkbox v-model="SearchSite" value="Lowes" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold">Lowes</div></template></v-checkbox>
    <v-checkbox v-model="SearchSite" value="Wayfair" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold">Waifair</div></template></v-checkbox>
    <v-checkbox v-model="SearchSite" value="Costco" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold">Costco</div></template></v-checkbox>

    <!-- <v-checkbox v-model="SearchSite" label="AmazonUS" value="AmazonUS" class="ma-2"></v-checkbox>
    <v-checkbox v-model="SearchSite" label="eBay" value="Ebay" class="ma-2"></v-checkbox>
    <v-checkbox v-model="SearchSite" label="Aliexpress" value="Aliexpress" class="ma-2"></v-checkbox> -->
  </v-row>

  </v-container>
</template>
<style>
  .t{
    text-shadow: 
    black 2px 0px,  black -2px 0px,
    black 0px -2px, black 0px 2px,
    black 2px 2px , black -2px 2px,
    black 2px -2px, black -2px -2px,
    black 1px 2px,  black -1px 2px,
    black 1px -2px, black -1px -2px,
    black 2px 1px,  black -2px 1px,
    black 2px -1px, black -2px -1px;
  }
  .tw{
    text-shadow: 
    white 2px 0px,  white -2px 0px,
    white 0px -2px, white 0px 2px,
    white 2px 2px , white -2px 2px,
    white 2px -2px, white -2px -2px,
    white 1px 2px,  white -1px 2px,
    white 1px -2px, white -1px -2px,
    white 2px 1px,  white -2px 1px,
    white 2px -1px, white -2px -1px;
  }
</style>
<script>
export default {
  name: "US",
  metaInfo:{
    title: "EC Digger -for quick shopping with the best choice",
    htmlAttrs:{
      lang: 'en-US'
    },
    meta: [
      {charset: 'utf-8'},
      {name: 'description', content: 'This site enables you to search major US e-commerce sites ,Amazon, eBay, Walmart, etc. at the same time.We helps you to find the products you want at the best price quickly.'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'}
    ]
  },
  data () {
      return {
        SearchSite:[],
        keyword: '',
        can_submit_search: false,
      }
    },
  created(){
    var cookie_array = this.getSiteFromCookie("US")
    console.log("cookie_array:"+cookie_array)
    console.log("cookie:"+document.cookie)
    if (cookie_array == null){
      var initialSites = ["Amazon","Ebay","Walmart","Etsy","Google","Target","Bestbuy","Macy's","Mercari","Homedepot","Wish","Lowes", "Wayfair", "Costco"]
      for (var elem of initialSites){
        this.SearchSite.push(elem)
      }
      this.setSiteToCookie("US", initialSites)
    }else{
      for (elem of cookie_array){
        this.SearchSite.push(elem)
      }
    }
  },
  watch:{
    SearchSite:function(){
      this.setSiteToCookie("US", this.SearchSite, document)
    }
  },
  methods:{
    getSiteFromCookie(key){
      var tmp1 = " "+document.cookie+";"
      console.log("tmp1:"+tmp1)
      var tmp2 = ""
      var tmp3 = ""
      var xx1 = 0
      var xx2 = 0
      var xx3 = 0
      var len = tmp1.length;
      while (xx1 < len){
        xx2 = tmp1.indexOf(";", xx1)
        // console.log(xx1)
        // console.log(xx2)
        tmp2 = tmp1.substring(xx1+1, xx2)
        // console.log(tmp2)
        xx3 = tmp2.indexOf("=")
        // console.log(xx3)
        console.log("thisKey: "+tmp2.substring(0, xx3))
        if (tmp2.substring(0, xx3) == key) {
            tmp3 = tmp2.substring(xx3+1, xx2-xx1-1)
            break
        }
        xx1 = xx2 + 1
      }
      // console.log(tmp3)
      if (tmp3 == ""){
        return null;
      }else{
        var sites_array = tmp3.split(",")
        return sites_array
      }
      // console.log(key)
    },


    // incorporateKeyToCookie(key, tmp){
    //   var tmp1 = document.cookie+";"
    //   var tmp2 = ""
    //   var tmp3 = ""
    //   var xx1 = 0
    //   var xx2 = 0
    //   var xx3 = 0
    //   var len = tmp1.length;
    //   while (xx1 < len){
    //     xx2 = tmp1.indexOf(";", xx1)
    //     // console.log(xx1)
    //     // console.log(xx2)
    //     tmp2 = tmp1.substring(xx1+1, xx2)
    //     // console.log(tmp2)
    //     xx3 = tmp2.indexOf("=")
    //     // console.log(xx3)
    //     var thisKey = tmp2.substring(0, xx3)
    //     if (thisKey != key) {
    //       if (thisKey != ""){
    //         if (thisKey != "expires"){
    //           tmp3 += thisKey + "="
    //         tmp3 += tmp2.substring(xx3+1, xx2-xx1)
    //         tmp3 += ";"
    //         break
    //       }
    //     }
    //     }
    //     xx1 = xx2 + 1
    //   }
    //   tmp3 += tmp;
    //   tmp3 += "expires=Tue, 31-Dec-2030 23:59:59;";
    //   return tmp3
    // },

      setSiteToCookie(key, site_array){
        var tmp = key + "=";
        for (var i = 0; i < site_array.length; i++){
          tmp += site_array[i];
          if (i  != site_array.length - 1){
            tmp += ","
          }
        }
        tmp += ";";
        // tmp = this.incorporateKeyToCookie(key, tmp)
        console.log("updated_cookie1: "+tmp)
        document.cookie = tmp
        console.log("updated_cookie2: "+document.cookie)

      },

    enable_submit() {
      this.can_submit_search = true;
    },

    submitEnter() {
      if (!this.can_submit_search) return;
      this.openSites(this.keyword, this.SearchSite)
      this.keyword = ""
      this.can_submit_search = false;
    },

    submitBtn(){
      this.openSites(this.keyword, this.SearchSite)
      this.keyword = ""
      this.can_submit_search = false;
    },


    openSites(keyword, SearchSites){
      if (keyword == "") return;
      var site_dict =["Amazon","Ebay","Walmart","Etsy","Google","Target","Bestbuy","Macy's","Mercari","Homedepot","Wish","Lowes", "Wayfair", "Costco"]
      var sites = []
      for (const site of site_dict){
        for (const selected_site of SearchSites){
          if (site == selected_site){
            sites.push(site);
          }
        }
      }
      var url_list = [];
      var url = '';
      for (const site of sites){
        if (site == "Amazon"){
          // url = "https://www.amazon.com/s?k="+keyword
          url = "https://www.amazon.com/gp/search?ie=UTF8&tag=jfweahjg-20&linkCode=ur2&camp=1789&creative=9325&index=aps&keywords="+keyword
        }
          if (site == "Ebay"){
          //   url = "https://www.ebay.com/sch/i.html?_from=R40&_nkw="+keyword
            url = "https://www.ebay.com/sch/i.html?_from=R40&_nkw="+keyword+"&mkcid=1&mkrid=711-53200-19255-0&siteid=0&campid=5338901341&customid=&toolid=10001&mkevt=1"
          }
        if (site == "Walmart"){
          url = "https://www.walmart.com/search?q="+keyword
        }
        if (site == "Etsy"){
          url = "https://www.etsy.com/jp/search?q="+keyword
        }
        if (site == "Google"){
          url = "https://www.google.com/search?q="+keyword
        }
        if (site == "Target"){
          url = "https://www.target.com/s?searchTerm="+keyword
        }
        if (site == "Bestbuy"){
          url = "https://www.bestbuy.com/site/searchpage.jsp?st="+keyword+"&_dyncharset=UTF-8"
        }
        if (site == "Macy's"){
          url = "https://www.macys.com/shop/featured/"+keyword
        }
        if (site == "Homedepot"){
          url = "https://www.homedepot.com/s/"+keyword
        }
        if (site == "Wish"){
          url = "https://www.wish.com/search/"+keyword
        }
        if (site == "Mercari"){
          url = "https://www.mercari.com/search/?keyword="+keyword
        }
        if (site == "Wayfair"){
          url = "https://www.wayfair.com/keyword.php?keyword="+keyword
        }
        if (site=="Lowes"){
          url = "https://www.lowes.com/search?searchTerm="+keyword
        }
        if (site=="Costco"){
          url = "https://www.costco.com/CatalogSearch?dept=All&keyword="+keyword
        }
        url_list.push(url)
      }
      for (const url of url_list.reverse()){
        window.open(url, '_blank');
      }

    }
  }
}
</script>
