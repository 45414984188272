<template>
  <v-app>
    <v-app-bar app dark>
      <component :is="headerComponent"></component>
      <!-- <v-btn depressed class="white--text text-h6" text @click="tips">米国版</v-btn> -->
    </v-app-bar>
    <v-content class="bg">
      <v-container fluid fill-height align-start>
        <router-view/>
      </v-container>
    </v-content>
    <v-footer
        dark
      >
        <v-row
          justify="center"
          no-gutters
        >
        <component :is="RegulationComponent"></component>
        <component :is="PrivacyPolicyComponent"></component>
        <!-- <Regulation/> -->
        <!-- <PrivacyPolicy/> -->
        </v-row>
      </v-footer>
  </v-app>
</template>

<style>
  /* .bg{
    /* background-image:url("~@/assets/google-earth-view-6293.jpg"); */
    /* background-image:url("~@/assets/view.jpg"); */
    /* background-size:cover; */
  /* }  */
</style>


<script>
import Regulation from "./components/Regulation.vue"
import PrivacyPolicy from "./components/PrivacyPolicy.vue"
import JpHeader from "./components/JpHeader.vue"
import UsHeader from "./components/UsHeader.vue"
import JpRegulation from "./components/Regulation.vue"
import UsRegulation from "./components/UsRegulation.vue"
import JpPrivacyPolicy from "./components/PrivacyPolicy.vue"
import UsPrivacyPolicy from "./components/UsPrivacyPolicy.vue"
import JP from "./views/Home.vue"
import US from "./views/US.vue"

export default {
  name: 'App',
  components: {
    Regulation,
    PrivacyPolicy,
    JpHeader,
    UsHeader,
    JpRegulation,
    UsRegulation,
    JpPrivacyPolicy,
    UsPrivacyPolicy,
    JP,
    US
  },
    metaInfo: {
    title: 'EC Digger - for quick shopping with the best choice',
  },
  computed:{
    headerComponent(){
      switch (this.$route.path){
        case '/':
          return 'JpHeader';
        case '/us':
          return 'UsHeader';
        default:
          return 'JpHeader';
      }
    },
    RegulationComponent(){
      switch (this.$route.path){
        case '/':
          return 'JpRegulation';
        case '/us':
          return 'UsRegulation';
        default:
          return 'JpRegulation';
      }
    }
    ,
    PrivacyPolicyComponent(){
      switch (this.$route.path){
        case '/':
          return 'JpPrivacyPolicy';
        case '/us':
          return 'UsPrivacyPolicy';
        default:
          return 'JpPrivacyPolicy';
      }
    }
  }
}

</script>