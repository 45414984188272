<template>
  <v-container text-center justify-center>
    <v-row justify="center">
      <h1 class="text-h2 pt-10 mt-5 font-weight-bold black--text">EC Digger</h1>
    </v-row>
    <v-row justify="center">
      <h1 class="text-h5 mt-3 font-weight-bold black--text">日本版</h1>
    </v-row>
    <v-row justify="center">
      <p class="text-h5 mt-1 mb-n5 font-weight-bold black--text">ーショッピングサイトを一括検索ー</p>
    </v-row>


    <v-row justify="center" class="mt-15 pt-5">
      <p class="black--text font-weight-bold">Amazon、楽天、メルカリなどの複数のショッピングサイトを一括で検索できます。</p>
    </v-row>
    <v-row justify="center">
      <p class="black--text font-weight-bold mt-n3">PCのみ対応で、米国版も利用可能です。</p>
    </v-row>
    <v-row justify="center">
      <p class="red--text font-weight-bold mt-n3">初回利用時にはポップアップブロックを解除してください。</p>
    </v-row>

  <v-form class="mt-10 mb-n10" @submit.prevent>
    <v-container>
      <v-row>
        <v-col cols="2">
        </v-col>
        <v-col cols="8">
        <!-- <v-col cols="9"> -->
          <v-text-field
            v-model="keyword"
            background-color="white"
            outlined
            clearable
            label="商品キーワード"
            type="text"
            @keypress.prevent.enter.exact="enable_submit"
            @keyup.prevent.enter.exact="submitEnter"
          >
            <template v-slot:prepend>
              <v-tooltip
                bottom
              >
                I'm a tooltip
              </v-tooltip>
            </template>
            <template v-slot:append>
              <v-fade-transition leave-absolute>
                <v-progress-circular
                  v-if="loading"
                  size="24"
                  color="info"
                  indeterminate
                ></v-progress-circular>
              </v-fade-transition>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="1">
          <v-btn x-large class="ml-n4 cyan lighten-2" @click="submitBtn">
            <v-icon class="white--text">mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>



  <template>
  <v-row justify="center" no-gutters class="mt-12 text-h5">
    <p class="text-justify text-h4 black--text">
    検索サイト
    </p>
  </v-row>
  </template>

  <v-row justify="center" no-gutters>
    <v-checkbox v-model="SearchSite" value="AmazonJP" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold">AmazonJP</div></template></v-checkbox>
    <v-checkbox v-model="SearchSite" value="Rakuten" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold">楽天</div></template></v-checkbox>
    <v-checkbox v-model="SearchSite" value="Yahoo" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold">Yahoo</div></template></v-checkbox>
    <v-checkbox v-model="SearchSite" value="Mercari" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold">メルカリ</div></template></v-checkbox>
    <v-checkbox v-model="SearchSite" value="Kakaku" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold">価格.com</div></template></v-checkbox>
  </v-row>
  <v-row justify="center" no-gutters>
    <v-checkbox v-model="SearchSite" value="Yahuoku" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold">ヤフオク</div></template></v-checkbox>
    <v-checkbox v-model="SearchSite" value="Paypay" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold">PayPayフリマ</div></template></v-checkbox>
    <v-checkbox v-model="SearchSite" value="Rakuma" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold">ラクマ</div></template></v-checkbox>
    <v-checkbox v-model="SearchSite" value="Yodobashi" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold">ヨドバシ</div></template></v-checkbox>
    <v-checkbox v-model="SearchSite" value="Google" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold">Google</div></template></v-checkbox>
    
  </v-row>
  <v-row justify="center" no-gutters>
    <v-checkbox v-model="SearchSite" value="Surugaya" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold">駿河屋</div></template></v-checkbox>
  <v-checkbox v-model="SearchSite" value="AmazonUS" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold">AmazonUS</div></template></v-checkbox>
    <v-checkbox v-model="SearchSite" value="Ebay" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold">eBay</div></template></v-checkbox>
    <v-checkbox v-model="SearchSite" value="Wish" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold">Wish</div></template></v-checkbox>
    <v-checkbox v-model="SearchSite" value="Aliexpress" class="ma-2"><template slot="label"><div style="color:black; font-weight:bold">Aliexpress</div></template></v-checkbox>
  </v-row>

  </v-container>
</template>
<style>
  .t{
    text-shadow: 
    black 2px 0px,  black -2px 0px,
    black 0px -2px, black 0px 2px,
    black 2px 2px , black -2px 2px,
    black 2px -2px, black -2px -2px,
    black 1px 2px,  black -1px 2px,
    black 1px -2px, black -1px -2px,
    black 2px 1px,  black -2px 1px,
    black 2px -1px, black -2px -1px;
  }
  .tw{
    text-shadow: 
    white 2px 0px,  white -2px 0px,
    white 0px -2px, white 0px 2px,
    white 2px 2px , white -2px 2px,
    white 2px -2px, white -2px -2px,
    white 1px 2px,  white -1px 2px,
    white 1px -2px, white -1px -2px,
    white 2px 1px,  white -2px 1px,
    white 2px -1px, white -2px -1px;
  }
</style>
<script>
import encoding from 'encoding-japanese';
function encode_shift_jis(keyword){
  const unicodeArray = [];
  for (let i = 0; i < keyword.length; i++) {
    unicodeArray.push(keyword.charCodeAt(i));
  }
  const sjisArray = encoding.convert(unicodeArray, {
  to: 'SJIS',
  from: 'UNICODE',
  });
  const encoded_keyword = encoding.urlEncode(sjisArray);
  return encoded_keyword
}

export default {
  name: "JP",
  metaInfo:{
    title: "EC Digger -ECサイト一括検索サイト、転売やせどりのリサーチの効率化に",
    htmlAttrs:{
      lang: 'ja'
    },
    meta: [
      {charset: 'utf-8'},
      {name: 'description', content: 'Amazon,楽天,Yahooをはじめとした国内外のECサイトを同時に検索できるサイトです。商品のキーワードを入力して検索を実行すると、選択したECサイトの検索結果がそれぞれ別タブで表示されます。本サイトを使うと、欲しい商品がお得に買えるサイトを素早く見つけることができます。'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'}
    ]
  },
  data () {
      return {
        SearchSite:[],
        keyword: '',
        can_submit_search: false,
      }
    },

created(){
    var cookie_array = this.getSiteFromCookie("JP")
    console.log("cookie_array:"+cookie_array)
    console.log("cookie:"+document.cookie)
    // console.log("cookie_array=" + cookie_array)
    if (cookie_array == null){
      var initialSites = ['AmazonJP', 'Rakuten', 'Yahoo', 'Mercari']
      // var initialSites = ['AmazonJP', 'Rakuten', 'Yahoo', 'Mercari',"Kakaku", "Yahuoku","Paypay","Rakuma","Yodobashi","Google"]
      // var initialSites = ['AmazonJP', 'Rakuten', 'Yahoo', 'Mercari',"Yahuoku","Paypay","Rakuma","Yodobashi","AmazonUS","Ebay","Wish","Aliexpress"]
      for (var elem of initialSites){
        this.SearchSite.push(elem)
      }
      // document.cookie = "JP="+initialSites+";"+"expires=Tue, 31-Dec-2030 23:59:59;"
      this.setSiteToCookie("JP", initialSites)
    }else{
      for (elem of cookie_array){
        this.SearchSite.push(elem)
      }
    }
  },
  watch:{
    // SearchSite:function(new_val, old_val){
    SearchSite:function(){
      // console.log(new_val)
      // console.log(old_val)
      this.setSiteToCookie("JP", this.SearchSite)
      // document.cookie = "JP=[AmazonJP, Rakuten, Yahoo!];"
      // console.log(document.cookie)
    }
  },
  methods:{
    getSiteFromCookie(key){
      var tmp1 = " "+document.cookie+";"
      console.log("tmp1:"+tmp1)
      var tmp2 = ""
      var tmp3 = ""
      var xx1 = 0
      var xx2 = 0
      var xx3 = 0
      var len = tmp1.length;
      while (xx1 < len){
        xx2 = tmp1.indexOf(";", xx1)
        // console.log(xx1)
        // console.log(xx2)
        tmp2 = tmp1.substring(xx1+1, xx2)
        // console.log(tmp2)
        xx3 = tmp2.indexOf("=")
        // console.log(xx3)
        if (tmp2.substring(0, xx3) == key) {
            tmp3 = tmp2.substring(xx3+1, xx2-xx1-1)
            break
        }
        xx1 = xx2 + 1
      }
      // console.log(tmp3)
      if (tmp3 == ""){
        return null;
      }else{
        var sites_array = tmp3.split(",")
        return sites_array
      }
      // console.log(key)
    },

    setSiteToCookie(key, site_array){
      var tmp = key + "=";
      for (var i = 0; i < site_array.length; i++){
        tmp += site_array[i];
        if (i  != site_array.length - 1){
          tmp += ","
        }
      }
      tmp += ";";
      console.log("updated_cookie1: "+tmp)
      document.cookie=tmp;
      console.log("updated_cookie2: "+document.cookie)
  
    },

    enable_submit() {
      this.can_submit_search = true;
    },

    submitEnter() {
      if (!this.can_submit_search) return;
      this.openSites(this.keyword, this.SearchSite)
      this.keyword = ""
      this.can_submit_search = false;
    },

    submitBtn(){
      this.openSites(this.keyword, this.SearchSite)
      this.keyword = ""
      this.can_submit_search = false;
    },


    openSites(keyword, SearchSites){
      if (keyword == "") return;
      var site_dict =["AmazonJP","Rakuten","Yahoo","Mercari","Kakaku", "Yahuoku","Paypay","Rakuma","Yodobashi","Google", "Surugaya", "AmazonUS","Ebay","Wish","Aliexpress"]
      var sites = []
      for (const site of site_dict){
        for (const selected_site of SearchSites){
          if (site == selected_site){
            sites.push(site);
          }
        }
      }
      var url_list = [];
      var url = '';
      var encodeKey = ""
      var encodeReplacedKey = ""
      for (const site of sites){
        console.log(site)
        if (site == "AmazonJP"){
          encodeKey = encodeURI(keyword).trim()
          encodeReplacedKey = encodeKey.split('%').join('%25')
          url = "https://ck.jp.ap.valuecommerce.com/servlet/referral?sid=3629803&pid=887611341&vc_url=https%3A%2F%2Fwww.amazon.co.jp%2Fs%3Fk%3D"+encodeReplacedKey+"%26tag%3Dvc-22%26linkCode%3Dure"

        }
        if (site == "Rakuten"){
          encodeKey = encodeURI(keyword).trim()
          encodeReplacedKey = encodeKey.split('%').join('%25')
          url = "https://af.moshimo.com/af/c/click?a_id=3158342&p_id=54&pc_id=54&pl_id=616&url=https%3A%2F%2Fsearch.rakuten.co.jp"+"%2Fsearch%2Fmall%2F"+encodeReplacedKey+"%2F"
        }
        if (site == "Yahoo"){
          encodeKey = encodeURI(keyword).trim()
          url = "https://ck.jp.ap.valuecommerce.com/servlet/referral?sid=3629803&pid=887601007&vc_url=https%3A%2F%2Fshopping.yahoo.co.jp%2Fsearch%3Fp%3D"+encodeKey
        }
        if (site == "Mercari"){
          url = "https://jp.mercari.com/search?afid=0603112005&keyword="+keyword+"&order=asc&status=on_sale"
        }
        if (site == "Kakaku"){
          url = "https://kakaku.com/search_results/" + encode_shift_jis(keyword)
        }
        if (site == "Yahuoku"){
          url = "https://auctions.yahoo.co.jp/search/search?ei=utf-8&p="+keyword
        }
        if (site == "Paypay"){
          url = "https://paypayfleamarket.yahoo.co.jp/search/"+keyword
        }
        if (site == "Rakuma"){
          url = "https://fril.jp/s?query="+keyword+"&transaction=selling"
        }
        if (site == "Yodobashi"){
          url = "https://www.yodobashi.com/?word="+keyword
        }
        if (site == "Surugaya"){
          url = "https://www.suruga-ya.jp/search?category=&search_word="+keyword
        }
        if (site == "Google"){
            url = "https://www.google.com/search?q="+keyword
        }
        if (site == "AmazonUS"){
          url = "https://www.amazon.com/gp/search?ie=UTF8&tag=jfweahjg-20&linkCode=ur2&camp=1789&creative=9325&index=aps&keywords="+keyword
        }
        if (site == "Aliexpress"){
          keyword = keyword.trim().replace(/\s/g, "-");
          url = "https://ja.aliexpress.com/w/wholesale-" + keyword + ".html"
        }
        if (site == "Ebay"){
          url = "https://www.ebay.com/sch/i.html?_from=R40&_nkw="+keyword+"&mkcid=1&mkrid=711-53200-19255-0&siteid=0&campid=5338901341&customid=&toolid=10001&mkevt=1"
        }
        if (site == "Wish"){
          url = "https://www.wish.com/search/"+keyword
        }
        url_list.push(url)
      }
      for (const url of url_list.reverse()){
        window.open(url, '_blank');
      }

    }
  }
}
</script>
