import { render, staticRenderFns } from "./UsHeader.vue?vue&type=template&id=1a4f6aed"
var script = {}
import style0 from "./UsHeader.vue?vue&type=style&index=0&id=1a4f6aed&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports